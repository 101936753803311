import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../page-style.scss";

const data = [
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/17-july-1/pic1.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/17-july-1/pic2.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/17-july-1/pic3.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/17-july-1/pic4.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/17-july-1/pic5.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/17-july-1/pic6.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/17-july-1/pic7.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/17-july-1/pic8.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/17-july-1/pic9.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/17-july-1/pic10.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/17-july-1/pic11.JPG",
];

export default function WorkshopOnPocsoAndPosh() {
  const settings = {
    // dots: true,
    infinite: true,
    arrows: true,
    // fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">Workshop on POCSO and POSH</h1>

        <p>
          Chinmaya Vidyalaya, New Delhi, conducted an informative workshop on
          POCSO and POSH Acts that enlightened the staff about the criticality
          of child safety and equipping them with effective strategies to
          address it. <br />
          <br />
          The workshop provided valuable insights on different forms of child
          sexual harassment, elucidating the corresponding punishments under IPC
          Acts. In addition to it, the importance of monitoring sudden changes
          in a child's behavior, academic performance, and class participation
          as potential indicators of alarming situations was emphasized.
          <br />
          <br />
          The session also shed light on the POSH Act of 2013, its objective of
          prevention, protection, and redressal and the concept of Quid Pro Quo.
          The workshop concluded with an engaging interactive questionnaire that
          was based on real-life case histories. This allowed the participants
          to apply their newfound knowledge and understanding of the POCSO and
          POSH Acts to practical scenarios. The questionnaire provided an
          opportunity to the staff to discuss and analyse different approaches
          to handling child sexual abuse and gender safety issues.
          <br />
          <br />
          The Principal, Ms. Archana Soni, underscored the role of these codes
          in promoting a safe and respectful atmosphere that supports the
          well-being and professional growth of all staff members.
        </p>
        <section className="kartavyapath__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
